import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map, Observable, ReplaySubject, switchMap, take, tap } from 'rxjs';
import { LastUserActivity } from './userActivity.types';


@Injectable({
    providedIn: 'root'
})
export class UserActivityService {

    private _userActivities: ReplaySubject<LastUserActivity[]> = new ReplaySubject<LastUserActivity[]>(1);

    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     */
    get userActivities$(): Observable<LastUserActivity[]> {
        return this._userActivities.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get all messages
     */
    getUserActivities(): Observable<LastUserActivity[]> {


        return this._httpClient.get<LastUserActivity[]>('api/Admin/LastUserActivity').pipe(
            tap((userActivities) => {
                this._userActivities.next(userActivities);
            })
        );
    }


    searchUserActivities(search: string): Observable<LastUserActivity[]> {

            const params = new HttpParams()
                .set('SearchValue', search);

        return this._httpClient.get<LastUserActivity[]>('api/Admin/LastUserActivity', { params }).pipe(
            tap((userActivities) => {
                this._userActivities.next(userActivities);
            })
        );
    }


}
