import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { MatButton } from '@angular/material/button';
import { Subject, takeUntil } from 'rxjs';
import { ImpersonationUser } from './impersonation.types';
import { ImpersonationService } from './impersonation.service';
import { User } from '../../../core/user/user.types';
import { UserService } from '../../../core/user/user.service';
import { Route, Router } from '@angular/router';
import { AlertService } from '@full-fledged/alerts';

@Component({
    selector: 'impersonation',
    templateUrl: './impersonation.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.Default,
    exportAs: 'impersonation'
})
export class ImpersonationComponent implements OnInit, OnDestroy {
    @ViewChild('impersonationOrigin') private _impersonationOrigin: MatButton;
    @ViewChild('impersonationPanel') private _impersonationPanel: TemplateRef<any>;

    mode: 'view' | 'modify' | 'add' | 'edit' = 'view';
    impersonationForm: UntypedFormGroup;
    impersonationUsers: ImpersonationUser[];
    private _overlayRef: OverlayRef;
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    user: User;
    impersonationActive: boolean;


    /**
     * Constructor
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _formBuilder: UntypedFormBuilder,
        private _overlay: Overlay,
        private _impersonationService: ImpersonationService,
        private _viewContainerRef: ViewContainerRef,
        private _userService: UserService,
        private _router: Router,
        private _alertService: AlertService
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {

        // Subscribe to the user service
        this._userService.user$
            .pipe((takeUntil(this._unsubscribeAll)))
            .subscribe((user: User) => {
                this.user = user;
                this.impersonationActive = user.impersonationActive;
            });


        // Initialize the form
        this.impersonationForm = this._formBuilder.group({
            search: [''],
        });


        
        // Get the shortcuts
        this._impersonationService.getImpersonationUsers()
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((impersonationUsers: ImpersonationUser[]) => {

                // Load the shortcuts
                this.impersonationUsers = impersonationUsers;

                // Mark for check
                this._changeDetectorRef.markForCheck();
            });

    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();

        // Dispose the overlay
        if (this._overlayRef) {
            this._overlayRef.dispose();
        }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------





    searchUser(): void {
        // Reset the form with the shortcut

        var _Value: string = this.impersonationForm.get('search').value;

        // Get the shortcuts
        this._impersonationService.searchImpersonationUsers(_Value)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((impersonationUsers: ImpersonationUser[]) => {

                // Load the shortcuts
                this.impersonationUsers = impersonationUsers;

                // Mark for check
                this._changeDetectorRef.markForCheck();
            });

        this._userService.logActivity('action', 'search-impersonation-user', null, _Value);

    }

    /**
     * Open the shortcuts panel
     */
    openPanel(): void {
        // Return if the shortcuts panel or its origin is not defined
        if (!this._impersonationPanel || !this._impersonationOrigin) {
            return;
        }

        // Make sure to start in 'view' mode
        this.mode = 'view';

        // Create the overlay if it doesn't exist
        if (!this._overlayRef) {
            this._createOverlay();
        }

        // Attach the portal to the overlay
        this._overlayRef.attach(new TemplatePortal(this._impersonationPanel, this._viewContainerRef));
    }

    /**
     * Close the shortcuts panel
     */
    closePanel(): void {
        this._overlayRef.detach();
    }

    /**
     * Change the mode
     */
    changeMode(mode: 'view' | 'modify' | 'add' | 'edit'): void {
        // Change the mode
        this.mode = mode;
    }

    /**
     * Prepare for a new shortcut
     */
    cancelImpersonation(): void {
        this._impersonationService.cancel();
        this._changeDetectorRef.markForCheck();
        this.closePanel();
        this._alertService.success('Der Identitätswechsel wird beendet ...');

        this._userService.logActivity('action', 'cancel-impersonation');

        setTimeout(function () {
            window.location.reload()
        }, 1000);
    }

    selectUser(impersonationUser: ImpersonationUser): void
    {
        this._impersonationService.start(impersonationUser.id);
        this._userService.logActivity('action', 'start-impersonation', impersonationUser.id);
        this._changeDetectorRef.markForCheck();
        this.closePanel();
        this._alertService.success('Der Identitätswechsel wird durchgeführt ...');

        setTimeout(function() {
            window.location.reload()
        }, 1000);
    }


    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item: any): any {
        return item.id || index;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Create the overlay
     */
    private _createOverlay(): void {
        // Create the overlay
        this._overlayRef = this._overlay.create({
            hasBackdrop: true,
            backdropClass: 'fuse-backdrop-on-mobile',
            scrollStrategy: this._overlay.scrollStrategies.block(),
            positionStrategy: this._overlay.position()
                .flexibleConnectedTo(this._impersonationOrigin._elementRef.nativeElement)
                .withLockedPosition(true)
                .withPush(true)
                .withPositions([
                    {
                        originX: 'start',
                        originY: 'bottom',
                        overlayX: 'start',
                        overlayY: 'top'
                    },
                    {
                        originX: 'start',
                        originY: 'top',
                        overlayX: 'start',
                        overlayY: 'bottom'
                    },
                    {
                        originX: 'end',
                        originY: 'bottom',
                        overlayX: 'end',
                        overlayY: 'top'
                    },
                    {
                        originX: 'end',
                        originY: 'top',
                        overlayX: 'end',
                        overlayY: 'bottom'
                    }
                ])
        });

        // Detach the overlay from the portal on backdrop click
        this._overlayRef.backdropClick().subscribe(() => {
            this._overlayRef.detach();
        });
    }
}
