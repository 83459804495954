<!-- toggle -->
<button mat-icon-button   [matTooltip]="'Benutzer Aktivitäten'"
        (click)="openPanel()"
        #userActivityOrigin>
    <mat-icon [svgIcon]="'heroicons_outline:cursor-click'"></mat-icon>
</button >




<!-- Shortcuts panel -->
<ng-template #userActivityPanel>
    <div class="fixed inset-0 sm:static sm:inset-auto flex flex-col sm:min-w-90 sm:w-90 sm:rounded-2xl overflow-hidden shadow-lg">

        <!-- Header -->
        <div class="flex shrink-0 items-center py-4 pr-4 pl-6 bg-primary-600 text-on-primary">
            <div class="sm:hidden -ml-1 mr-3">
                <button mat-icon-button
                        (click)="closePanel()">
                    <mat-icon class="icon-size-5 text-current"
                              [svgIcon]="'heroicons_solid:x'"></mat-icon>
                </button>
            </div>
            <div class="flex text-lg font-medium leading-10">
                <span class="">Letzte Benutzer Aktivitäten</span>
            </div>

        </div>

        <div class="relative  bg-card">


            <form [formGroup]="userActivityForm" class="p-3">
                <mat-form-field class="fuse-mat-no-subscript w-full mt-1"
                                [floatLabel]="'always'" [placeholder]="'Benutzer suchen'">
                    <input [formControlName]="'search'" matInput />
                    <button mat-icon-button
                            [disabled]="!userActivityForm.valid"
                            matSuffix (click)="searchUser()">
                        <mat-icon class="icon-size-5"
                                  [svgIcon]="'heroicons_solid:search'"></mat-icon>
                    </button>
                </mat-form-field>


            </form>

            <!-- View mode -->
            <ng-container *ngIf="userActivities">



                <!-- Shortcuts -->
                <div class="grid grid-cols-1 grid-flow-row flex flex-col flex-auto sm:max-h-90 -mb-px overflow-y-auto">




                    <!-- Shortcut -->
                    <ng-container *ngFor="let userActivityUser of userActivities; trackBy: trackByFn">
                        <div class="relative group flex flex-col overflow-hidden bg-card border-r border-b even:border-r-0 hover:bg-gray-50 dark:hover:bg-black dark:hover:bg-opacity-5">

                            <ng-container>
                                <div class="absolute inset-0 z-99 cursor-pointer"
                                     (click)="selectUser(userActivityUser)">
                                </div>
                            </ng-container>
                            <!-- Normal links -->
                            <a class="flex flex-col items-center justify-center w-full h-full py-3 no-underline">
                                <div class="font-medium text-center">{{userActivityUser.userName}}</div>
                                <div class="text-md text-center text-secondary">{{userActivityUser.dateLastActivity | date: 'dd.MM.yyyy HH:mm'}}</div>
                            </a>

                        </div>
                    </ng-container>
                </div>

                <!-- No shortcuts -->
                <ng-container *ngIf="!userActivities || !userActivities.length">
                    <div class="flex flex-col flex-auto items-center justify-center sm:justify-start py-12 px-8">
                        <div class="flex flex-0 items-center justify-center w-14 h-14 rounded-full bg-primary-100 dark:bg-primary-600">
                            <mat-icon class="text-primary-700 dark:text-primary-50"
                                      [svgIcon]="'heroicons_outline:users'"></mat-icon>
                        </div>
                        <div class="mt-5 text-2xl font-semibold tracking-tight">Keine Aktivitäten</div>
                        <div class="w-full max-w-60 mt-1 text-md text-center text-secondary">Keine protokollierten Aktivitäten.</div>
                    </div>
                </ng-container>
            </ng-container>


        </div>
    </div>
</ng-template>
