import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { catchError, map, Observable, of, switchMap, throwError, tap } from 'rxjs';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { UserService } from 'app/core/user/user.service';
import { CI_Settings, User } from '../user/user.types';
import { isUndefined } from 'lodash';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from '../common/common.service';
import { CustomTheme } from '../common/common.types';
import { ConfirmEmailResult, RegisterRequest, ResetPasswordResult } from './auth.types';
import { OrganizationService } from '../organization/organization.service';

@Injectable()
export class AuthService {
    private _authenticated: boolean = false;


    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient,
        private _userService: UserService,
        private _organizationService: OrganizationService,
        private _commonService: CommonService,
        private _router: Router
    ) {



    }


    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for access token
     */
    set accessToken(token: string) {
        localStorage.setItem('accessToken', token);
    }



    get accessToken(): string {



        return localStorage.getItem('accessToken') ?? '';
    }

    get settings(): CI_Settings {
        return {
            title: localStorage.getItem('title') ?? 'Scalooper',
            theme: localStorage.getItem('theme') ?? 'theme-scalooper',
            logo: {
                normal: localStorage.getItem('logo.normal') ?? 'assets/images/logo/scalooper/scalooper-logo.png',
                dark: localStorage.getItem('logo.dark') ?? 'assets/images/logo/scalooper/scalooper-dark.png',
                text: localStorage.getItem('logo.text') ?? 'assets/images/logo/scalooper/scalooper-text.png'
            }
        }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Forgot password
     *
     * @param email
     */



    forgotPassword(email: string): Observable<boolean> {

        const params = new HttpParams()
            .set('email', email);

        return this._httpClient.post<boolean>('api/User/ForgotPassword', null, { params }).pipe(
            map((result: boolean) => {
                return result;
            })
        )
    };



    confirmEmail(token: string): Observable<ConfirmEmailResult> {

        const params = new HttpParams()
            .set('token', token);


        return this._httpClient.post<ConfirmEmailResult>('api/User/ConfirmEmail', null, { params }).pipe(
            map((confirmEmailResult: ConfirmEmailResult) => {
                return confirmEmailResult;
            })
        )
    };

    sendConfirmationEmail(email: string): Observable<boolean> {

        const params = new HttpParams()
            .set('email', email);



        return this._httpClient.post<boolean>('api/User/SendConfirmationEmail', null, { params }).pipe(
            map((success: boolean) => {
                return success;
            })
        )
    };


    resetPassword(token: string, password: string): Observable<ResetPasswordResult> {

        const params = new HttpParams()
            .set('token', token)
            .set('password', password);


        return this._httpClient.post<ResetPasswordResult>('api/User/ResetPassword', null, { params }).pipe(
            map((changePasswordResult: ResetPasswordResult) => {
                return changePasswordResult;
            })
        )
    };






    get isAuthenticated(): boolean {
        return this._authenticated;
    }
    /**
     * Sign in
     *
     * @param credentials
     */
    signIn(credentials: { email: string; password: string }): Observable<any> {
        // Throw error, if the user is already logged in
        if (this._authenticated) {
            return throwError('User is already logged in.');
        }

        return this._httpClient.post('api/Authorize/SignIn', credentials).pipe(
            switchMap((response: any) => {


                // Store the access token in the local storage
                this.accessToken = response.accessToken;

                // Set the authenticated flag to true
                this._authenticated = true;

                // Store the user on the user service
                this._userService.user = response.user;
              
                this._userService.setStatus('online');
                response.user.status = 'online';
                this._userService.logActivity('login');

                console.log('accessToken', response.accessToken);

                localStorage.setItem('user', response.user);
                localStorage.setItem('theme', response.user.theme);
                localStorage.setItem('title', response.user._Theme.title);
                localStorage.setItem('logo.text', response.user._Theme.logoTextPath);
                localStorage.setItem('logo.normal', response.user._Theme.logoPath);
                localStorage.setItem('logo.dark', response.user._Theme.logoDarkPath);

                // Return a new observable with the response
                return of(response);
            })
        );
    }




    hasPermission(permissionName: string): Observable<boolean> {

        const params = new HttpParams()
            .set('PermissionName', permissionName);



        console.log('permission', permissionName);

        return this._httpClient.get<boolean>('api/User/HasPermission', { params: params }).pipe(
            tap((response: boolean) => {

                console.log('permission', response);
                return of(response);
            }),
            catchError(err => of(false))
        );
    }

    hasPermissionOriginal(permissionName: string): Observable<boolean> {

        const params = new HttpParams()
            .set('PermissionName', permissionName);

        console.log('manual permission', permissionName);


        return this._httpClient.get<boolean>('api/User/HasPermissionOriginal', { params: params }).pipe(
            tap((response: boolean) => {
                console.log('manuel permission', response);
                return of(response);
            }),
            catchError(err => { console.error(err); return of(false) })
        );
    }

    hasRole(roleName: string): Observable<boolean> {

        const params = new HttpParams()
            .set('RoleName', roleName);

        return this._httpClient.get<boolean>('api/User/HasRole', { params: params }).pipe(
            tap((response: boolean) => {


                return of(response);
            }), catchError(error => {
                console.error("Fehler bein Abfragen der Benutzer Rollen.", error);
                return of(false);
            })
        );


    }


    hasRoleOriginal(roleName: string): Observable<boolean> {

        const params = new HttpParams()
            .set('RoleName', roleName);

        return this._httpClient.get<boolean>('api/User/HasRoleOriginal', { params: params }).pipe(
            tap((response: boolean) => {


                return of(response);
            }), catchError(error => {
                console.error("Fehler bein Abfragen der Benutzer Rollen.", error);
                return of(false);
            })
        );


    }


    isAdmin(): Observable<boolean> {

        const params = new HttpParams();

        return this._httpClient.get<boolean>('api/User/IsAdmin', { params: params }).pipe(
            tap((response: boolean) => {

                return of(response);
            }), catchError(error => {
                console.error("Fehler bein Abfragen der Administrator Rolle.", error);
                return of(false);
            })
        );


    }





    /**
     * Sign in using the access token
     */
    signInUsingToken(): Observable<any> {

        // Sign in using the token
        return this._httpClient.post('api/Authorize/SignInWithAccessToken', {
            accessToken: this.accessToken
        }).pipe(
            catchError(async (error) => of(false)
                // Return false
            ),
            switchMap((response: any) => {

                this._userService.setStatus('offline');
                response.user.status = 'offline';

                this._userService.user = response.user;


              

                this._userService.logActivity('login');

                if (response.accessToken) {
                    this.accessToken = response.accessToken;

                    console.log('accessToken', response.accessToken);
                }


                localStorage.setItem('theme', response.user.theme);
                localStorage.setItem('title', response.user._Theme.title);
                localStorage.setItem('logo.text', response.user._Theme.logoTextPath);
                localStorage.setItem('logo.normal', response.user._Theme.logoPath);
                localStorage.setItem('logo.dark', response.user._Theme.logoDarkPath);

                // Set the authenticated flag to true
                this._authenticated = true;

                // Store the user on the user service

                // Return true
                return of(true);
            })
        );
    }

    /**
     * Sign out
     */
    signOut(): Observable<any> {

        this._userService.logActivity('logout');


        // Remove the access token from the local storage
        localStorage.removeItem('accessToken');

        localStorage.removeItem('theme');

        localStorage.removeItem('pathTextLogo');
        localStorage.removeItem('pathLogo');
        localStorage.removeItem('pathDarkLogo');


        // Set the authenticated flag to false
        this._authenticated = false;



        // Return the observable
        return of(true);
    }

    /**
     * Sign up
     *
     * @param user
     */
    register(registerData: RegisterRequest): Observable<any> {


        return this._httpClient.post('api/User/Register', registerData);
    }

    /**
     * Unlock session
     *
     * @param credentials
     */
    unlockSession(credentials: { email: string; password: string }): Observable<any> {


        return this._httpClient.post('api/auth/unlock-session', credentials);
    }

    /**
     * Check the authentication status
     */
    check(): Observable<boolean> {
        // Check if the user is logged in
        if (this._authenticated) {
            return of(true);
        }

        // Check the access token availability
        if (!this.accessToken) {
            return of(false);
        }

        // Check the access token expire date
        if (AuthUtils.isTokenExpired(this.accessToken)) {
            this._userService.logActivity('logout', 'Token Expired');
            return of(false);
        }

        // If the access token exists and it didn't expire, sign in using it
        return this.signInUsingToken();
    }
}
