import { Component, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { UserService } from '../../../core/user/user.service';
import { User } from '../../../core/user/user.types';


@Component({
    selector: 'impersonation-bar',
    templateUrl: './impersonation-bar.component.html',
    styleUrls: ['./impersonation-bar.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ImpersonationBarComponent implements OnDestroy {
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    user: User;
     show: boolean = true;
    /**
     * Constructor
     */
    constructor(private _userService: UserService) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
 * On init
 */
    ngOnInit(): void {


        // Subscribe to the user service
        this._userService.user$
            .pipe((takeUntil(this._unsubscribeAll)))
            .subscribe((user: User) => {
                this.user = user;

               

            });
    };
    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }
}


