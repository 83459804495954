<div *ngIf="team" class="fixed lg:sticky top-0 bottom-0 lg:left-full w-full sm:w-96 lg:w-16 lg:h-screen lg:shadow z-999" hidden>
    <div class="flex flex-col w-full sm:w-96 h-full transition-transform duration-400 ease-drawer bg-card"
         [ngClass]="{'-translate-x-full sm:-translate-x-96 lg:-translate-x-80 shadow': opened, 'translate-x-0': !opened}">

        <!-- Header -->
        <div class="quick-chat-header flex flex-0 items-center justify-start cursor-pointer"
             (click)="toggle()">

            <!-- Toggle -->
            <ng-container *ngIf="!opened || (opened && !selectedTeamMember)">
                <div class="flex flex-auto items-center justify-center">
                    <div class="flex flex-0 items-center justify-center w-16">
                        <mat-icon class="icon-size-6"
                                  [svgIcon]="'heroicons_outline:user-group'"></mat-icon>
                    </div>
                    <div class="text-lg font-medium text-secondary">Team</div>
                    <div class="text-sm font-medium text-primary pl-4">{{team.teamName}}</div>

                    <button class="ml-auto mr-4"
                            mat-icon-button>
                        <mat-icon [svgIcon]="'heroicons_outline:x'"></mat-icon>
                    </button>
                </div>
            </ng-container>

            <!-- Contact info -->
            <ng-container *ngIf="opened && selectedTeamMember">
                <div class="flex flex-auto items-center ml-3">
                    <div class="relative flex flex-0 items-center justify-center w-10 h-10">
                        <ng-container *ngIf="teamMember.avatar">
                            <img class="w-full h-full rounded-full object-cover"
                                 [src]="teamMember.avatar"
                                 alt="Contact avatar" />
                        </ng-container>
                        <ng-container *ngIf="!teamMember.avatar">
                            <div class="flex items-center justify-center w-full h-full rounded-full text-lg uppercase bg-gray-200 text-gray-600 dark:bg-gray-700 dark:text-gray-200">
                                {{teamMember.firstname.charAt(0)}}
                            </div>
                        </ng-container>
                    </div>
                    <div class="ml-4 text-lg font-medium leading-5 truncate">{{teamMember.firstname}} {{teamMember.lastname}}</div>
                    <button class="ml-auto mr-4"
                            mat-icon-button>
                        <mat-icon [svgIcon]="'heroicons_outline:x'"></mat-icon>
                    </button>
                </div>
            </ng-container>
        </div>

        <!-- Content -->
        <div class="flex flex-auto border-t overflow-hidden">

            <!-- Chat list -->
            <div class="flex-0 w-16 h-full overflow-y-auto overscroll-y-contain sm:overflow-hidden sm:overscroll-auto"
                 fuseScrollbar
                 [fuseScrollbarOptions]="{wheelPropagation: false}">
                <div class="flex-auto">
                    <ng-container *ngFor="let tm of teamMembers; trackBy: trackByFn">
                        <div class="flex items-center py-3 px-4 cursor-pointer"
                             [ngClass]="{'hover:bg-gray-100 dark:hover:bg-hover': !selectedTeamMember || selectedTeamMember.userid !== tm.userId,
                                        'bg-primary-50 dark:bg-hover': selectedTeamMember && selectedTeamMember.userId === tm.userId}"
                             (click)="selectTeamMember(tm.userId)">
                            <div class="relative flex flex-0 items-center justify-center w-8 h-8">
                                <ng-container>
                                    <div [ngClass]="{
                            'bg-green-500   dark:bg-green-500': tm.status === 'online',
                          'bg-amber-500   dark:bg-amber-500': tm.status === 'away',
                          'bg-red-500   dark:bg-red-500': tm.status === 'busy',
                          'bg-gray-400   dark:bg-gray-500': tm.status === 'not-visible',
                            'bg-gray-400   dark:bg-gray-500': tm.status === 'offline'}"
                                         class="absolute bottom-0 right-0 flex-0 w-2 h-2 -ml-0.5 rounded-full ring-2 ring-bg-card dark:ring-gray-900 text-on-primary "
                                         [class.ring-primary-50]="selectedTeamMember && selectedTeamMember.userId === tm.userId"></div>
                                </ng-container>
                                <ng-container *ngIf="tm.avatar">
                                    <img class="w-full h-full rounded-full object-cover"
                                         [src]="tm.avatar"
                                         alt="Contact avatar" />
                                </ng-container>
                                <ng-container *ngIf="!tm.avatar">
                                    <div class="flex items-center justify-center w-full h-full rounded-full text-lg uppercase bg-gray-200 text-gray-600 dark:bg-gray-700 dark:text-gray-200">
                                        {{tm.firstname.charAt(0)}}
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>

            <!-- Conversation -->
            <div class="flex flex-col flex-auto border-l overflow-hidden bg-gray-50 dark:bg-transparent">
                <ng-container *ngIf="selectedTeamMember; else selectChatOrStartNew">
                    <div class="flex flex-col overflow-y-auto overscroll-y-contain">
                        <ng-container *ngIf="selectedTeamMember.roleName != null">
                            <div class="flex  p-6 right-0">
                                <span *ngIf="selectedTeamMember.roleName === 'member'" class=" items-center font-bold text-xs px-2 py-0.5 rounded-full  uppercase bg-green-200 text-green-800 dark:bg-green-600 dark:text-green-50"><span class="leading-relaxed whitespace-nowrap">Mitglied</span></span>
                                <span *ngIf="selectedTeamMember.roleName === 'administrator'" class=" items-center font-bold text-xs px-2 py-0.5 rounded-full  uppercase bg-red-200 text-red-800 dark:bg-red-600 dark:text-red-50"><span class="leading-relaxed whitespace-nowrap">Ertsteller</span></span>
                                <span *ngIf="selectedTeamMember.roleName === 'team_leader'" class=" items-center font-bold text-xs px-2 py-0.5 rounded-full  uppercase bg-blue-200 text-blue-800 dark:bg-blue-600 dark:text-blue-50"><span class="leading-relaxed whitespace-nowrap">Team Leiter</span></span>


                                <div class="flex items-center pl-4">
                                    <div class="flex-0 w-2 h-2 rounded-full" [ngClass]="{
                            'bg-green-500   dark:bg-green-500': selectedTeamMember.status === 'online',
                          'bg-amber-500   dark:bg-amber-500': selectedTeamMember.status === 'away',
                          'bg-red-500   dark:bg-red-500': selectedTeamMember.status === 'busy',
                          'bg-gray-400   dark:bg-gray-500': selectedTeamMember.status === 'not-visible',
                            'bg-gray-400   dark:bg-gray-500': selectedTeamMember.status === 'offline'}"></div>
                                    <div class="ml-3 truncate" *ngIf="selectedTeamMember.status === 'online'">Online</div>
                                    <div class="ml-3 truncate" *ngIf="selectedTeamMember.status === 'away'">Abwesend</div>
                                    <div class="ml-3 truncate" *ngIf="selectedTeamMember.status === 'busy'">Beschäftigt</div>
                                    <div class="ml-3 truncate" *ngIf="selectedTeamMember.status === 'offline' || selectedTeamMember.status === 'not-visibile' || selectedTeamMember.status == null">Offline</div>
                                </div>

                            </div>
                        </ng-container>


                        
                        <div class="flex px-6">



                            <ng-container *ngIf="selectedTeamMember.email != null">
                                <div class="flex">
                                    <mat-icon svgIcon="feather:mail"></mat-icon>
                                    <div class="min-w-0 ml-3">
                                        <ng-container>
                                            <div class="flex items-center leading-6">
                                                <div><a href="mailto:{{selectedTeamMember.email}}">{{selectedTeamMember.email}}</a></div>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                            </ng-container>

                        </div>
                    </div>


                </ng-container>
            </div>
        </div>
    </div>
</div>

<!-- Select chat or start new template -->
<ng-template #selectChatOrStartNew>
    <div class="flex flex-col flex-auto items-center justify-center w-full h-full p-4">
        <mat-icon class="icon-size-24"
                  [svgIcon]="'heroicons_outline:user'"></mat-icon>
        <div class="mt-4 text-xl text-center font-medium tracking-tight text-secondary">Wähle ein Team Mitglied</div>
    </div>
</ng-template>

<!-- Speech bubble tail SVG -->
<!-- @formatter:off -->
<ng-template #speechBubbleExtension>
    <svg width="100%" height="100%" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <path d="M1.01522827,0.516204834 C-8.83532715,54.3062744 61.7609863,70.5215302 64.8009949,64.3061218 C68.8074951,54.8859711 30.1663208,52.9997559 37.5036011,0.516204834 L1.01522827,0.516204834 Z" fill="currentColor" fill-rule="nonzero"></path>
        </g>
    </svg>
</ng-template>
<!-- @formatter:on -->
