import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { catchError, map, Observable, ReplaySubject, switchMap, take, tap } from 'rxjs';
import { UseCodeResponse } from 'app/layout/common/code/code.types';

@Injectable({
    providedIn: 'root'
})


export class CodeService {
    private _code: ReplaySubject<UseCodeResponse> = new ReplaySubject<UseCodeResponse>(1);

    constructor(private _httpClient: HttpClient) {
    }


    useCode(code: string): Observable<UseCodeResponse> {

        const params = new HttpParams()
            .set('code', code);

        return this._httpClient.post('api/Code/UseCode', null, { params } ).pipe(
            tap((response: UseCodeResponse) => {
                return response;
            })
        );
    }
  
}
