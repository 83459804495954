import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { catchError, map, Observable, of, switchMap, throwError, tap, ReplaySubject, take } from 'rxjs';
import { ChangePasswordResult, LogActivity, User } from 'app/core/user/user.types';
import { AvailableLangs, TranslocoService } from '@ngneat/transloco';
import { CommonService } from '../common/common.service';
import { DOCUMENT } from '@angular/common';
import { isUndefined } from 'lodash';
import { InviteMemberResult, Organization, OrganizationListData, OrganizationMember } from './organization.types';


@Injectable({
    providedIn: 'root'
})


export class OrganizationService {

    private _organization: ReplaySubject<Organization> = new ReplaySubject<Organization>(1);
    private _organizationList: ReplaySubject<OrganizationListData[]> = new ReplaySubject<OrganizationListData[]>(1);

    private _organizationMembers: ReplaySubject<OrganizationMember[]> = new ReplaySubject<OrganizationMember[]>(1);
    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient, private _trancslocoService: TranslocoService, private _commonService: CommonService) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for user
     *
     * @param value
     */
    set organization(organization: Organization) {
        // Store the value
        this._organization.next(organization);
    }

    set members(organizationMembers: OrganizationMember[]) {
        // Store the value
        this._organizationMembers.next(organizationMembers);
    }

    get organization$(): Observable<Organization> {
        return this._organization.asObservable();
    }

    get members$(): Observable<OrganizationMember[]> {
        return this._organizationMembers.asObservable();
    }

    set organizationList(organizationList: OrganizationListData[]) {
        // Store the value
        this._organizationList.next(organizationList);
    }

    get organizationList$(): Observable<OrganizationListData[]> {

        return this._organizationList.asObservable();
    }


    update(organization: Organization): Observable<Organization> {
        return this._httpClient.patch<Organization>('api/Organization/Info', organization).pipe(
            map((updateOrganization: Organization) => {
                this._organization.next(updateOrganization);
                return updateOrganization;
            })
        )
    };

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get the current logged in user data
   * Get the current logged in user data
     */
    get(id: number): Observable<Organization> {

        const params = new HttpParams().set('OrganizationId', id);

        return this._httpClient.get<Organization>('api/Organization/Info', { params }).pipe(
            tap((organization) => {
                this._organization.next(organization);
            })
        );
    }

    getActive(): Observable<Organization> {


        return this._httpClient.get<Organization>('api/Organization/Info/Active').pipe(
            tap((organization) => {
                this._organization.next(organization);
            })
        );
    }

    delete(organizationId: number, memberNotification: boolean) {

        const params = new HttpParams()
            .set('OrganizationId', organizationId)
            .set('MemberNotification', memberNotification);

        return this._httpClient.delete<boolean>('api/Organization/Delete', { params }).subscribe();

    }


  
    deleteMember(memberUserId: string, organizationId: number): Observable<boolean> {

        const params = new HttpParams()
            .set('OrganizationId', organizationId)
            .set('MemberUserId', memberUserId);


        return this._httpClient.delete<boolean>('api/Organization/DeleteMember', { params }).pipe(
            map((result: boolean) => {
                return result;
            })
        )
    };


    inviteMember(email: string, organizationId: number): Observable<InviteMemberResult> {

        const params = new HttpParams()
            .set('Email', email)
            .set('OrganizationId', organizationId);


        return this._httpClient.post<InviteMemberResult>('api/Organization/InviteMember', null, { params }).pipe(
            map((result: InviteMemberResult) => {
                return result;
            })
        )
    };


    getUserOrganizationList(): Observable<OrganizationListData[]> {
        return this._httpClient.get<OrganizationListData[]>('api/Organization/List').pipe(
            tap((organizationListData) => {

                this._organizationList.next(organizationListData);
            })
        );
    }

    setActive(organizationId: number) {

        const params = new HttpParams()
            .set('OrganizationId', organizationId);

        this._httpClient.post('api/Organization/SetActive', null, { params }).subscribe();

    }


    changeMemberRole(memberUserId: string, organizationId: number, roleName: string) {

        const params = new HttpParams()
            .set('OrganizationId', organizationId)
            .set('MemberUserId', memberUserId)
            .set('RoleName', roleName);

        this._httpClient.post('api/Organization/Member/ChangeRole', null, { params }).subscribe();
    }

    getMembers(): Observable<any> {
        return this._httpClient.get<OrganizationMember[]>('api/Organization/Members').pipe(
            tap((response: OrganizationMember[]) => {
                this._organizationMembers.next(response);
            })
        );
    }


    searchMembers(searchValue: string): Observable<any> {
        const params = new HttpParams()
            .set('SearchValue', searchValue);

        return this._httpClient.get<OrganizationMember[]>('api/Organization/Members', { params }).pipe(
            tap((response: OrganizationMember[]) => {
                this._organizationMembers.next(response);
            })
        );
    }

}
