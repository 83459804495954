import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, map, Observable, of, switchMap, tap, throwError } from 'rxjs';
import {  Team, TeamMember } from 'app/layout/common/team-chat/team-chat.types';

@Injectable({
    providedIn: 'root'
})
export class TeamChatService
{
    private _team: BehaviorSubject<Team> = new BehaviorSubject(null);
    private _teamMember: BehaviorSubject<TeamMember> = new BehaviorSubject(null);
    private _teamMembers: BehaviorSubject<TeamMember[]> = new BehaviorSubject<TeamMember[]>(null);

    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient)
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for chat
     */
    get teamMember$(): Observable<TeamMember>
    {
        return this._teamMember.asObservable();
    }

    get team$(): Observable<Team> {
        return this._team.asObservable();
    }

    /**
     * Getter for chat
     */
    get teamMembers$(): Observable<TeamMember[]>
    {
        return this._teamMembers.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get chats
     */
    getTeamMembers(): Observable<any>
    {
        console.log('');

        return this._httpClient.get<TeamMember[]>('api/Team/Members').pipe(
            tap((response: TeamMember[]) => {
                this._teamMembers.next(response);
            })
        );
    }


    getTeam(): Observable<any> {

        return this._httpClient.get<Team>('api/Team/Info').pipe(
            tap((response: Team) => {
                this._team.next(response);
            })
        );
    }


    /**
     * Get chat
     *
     * @param userId
     */
    getTeamMemberById(userId: string): Observable<any>
    {
        return this._httpClient.get<TeamMember>('api/Team/Member', { params: { userId }}).pipe(
            map((teamMember) => {

                // Update the chat
                this._teamMember.next(teamMember);

                // Return the chat
                return teamMember;
            }),
            switchMap((teamMember) => {

                if (!teamMember )
                {
                    return throwError('Could not found member with id of ' + userId + '!');
                }

                return of(teamMember);
            })
        );
    }
}
