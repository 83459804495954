import { AfterViewInit, ChangeDetectorRef, Component, ContentChild, ElementRef, HostBinding, HostListener, Inject, Injectable, NgZone, OnDestroy, OnInit, Output, Renderer2, ViewChild, ViewChildren, ViewEncapsulation } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Subject, takeUntil } from 'rxjs';
import { User } from '../../../core/user/user.types';
import { AppConfig, Scheme, Theme, Themes } from '../../../core/config/app.config';
import { Layout } from '../../layout.types';
import { CustomTheme } from '../../../core/common/common.types';
import { UserService } from '../../../core/user/user.service';
import { FuseConfigService } from '../../../../@fuse/services/config';
import { CommonService } from '../../../core/common/common.service';
import { FuseDrawerComponent } from '@fuse/components/drawer';
import { ActivatedRoute, Router } from '@angular/router';

/*
@Injectable({
    providedIn: 'root'
})
*/

@Component({
    selector: 'design',
    templateUrl: './design.component.html',
    encapsulation: ViewEncapsulation.None,
    exportAs: 'design',
})



export class DesignComponent implements OnInit, OnDestroy {




    @ViewChild('design', {static: true}) design: FuseDrawerComponent;
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor
     */
    constructor(
        @Inject(DOCUMENT) private _document: Document,
        private _userService: UserService,
        private _changeDetectorRef: ChangeDetectorRef,
        private _fuseConfigService: FuseConfigService,
        private _commonService: CommonService,
        private _router: Router,
        private _activatedRoute: ActivatedRoute
        
    ) {
    }

    user: User;

    config: AppConfig;
    layout: Layout;
    scheme: 'dark' | 'light';
    theme: string;
    themes: Themes;
    customThemes: CustomTheme[];

    /**
     /**
     * On init
     */
    ngOnInit(): void {


        // Subscribe to user changes
        this._userService.user$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((user: User) => {
                this.user = user;

                // Mark for check
                this._changeDetectorRef.markForCheck();


                var layout: string = user.layout ?? "classy";
                var theme: string = user.theme ?? "theme-mbmedien";
                var scheme: string = user.scheme ?? "light";


                localStorage.setItem('theme', user.theme);
                localStorage.setItem('title', user._Theme.title);
                localStorage.setItem('logo.text', user._Theme.logoTextPath);
                localStorage.setItem('logo.normal', user._Theme.logoPath);
                localStorage.setItem('logo.dark', user._Theme.logoDarkPath);


                this.setLayout(layout, false);
                this.setTheme(theme, false);
                this.setScheme((scheme as Scheme), false);
            });




        // Subscribe to config changes
        this._fuseConfigService.config$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((config: AppConfig) => {


                // Store the config
                this.config = config;

            });

        

    }

   
    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

   

    toggleDrawer() {
      this.design.open();
    }

    /**
     * Set the layout on the config
     *
     * @param layout
     */
    setLayout(layout: string, UpdateDb: boolean = true): void {
        // Clear the 'layout' query param to allow layout changes
        this._router.navigate([], {
            queryParams: {
                layout: null
            },
            queryParamsHandling: 'merge'
        }).then(() => {

            // Set the config
            this._fuseConfigService.config = { layout };

            if (UpdateDb && this.user != null) {

                this.user.layout = layout;

                // Update the user
                this._userService.update({
                    ...this.user
                }).subscribe();

            }
        });
    }

    /**
     * Set the scheme on the config
     *
     * @param scheme
     */
    setScheme(scheme: Scheme, UpdateDb: boolean = true): void {
        this._fuseConfigService.config = { scheme };

        if (UpdateDb && this.user != null) {

            this.user.scheme = scheme.toString();

            // Update the user
            this._userService.update({
                ...this.user
            }).subscribe();

        }



    }

    /**
     * Set the theme on the config
     *
     * @param theme
     */
    setTheme(theme: Theme, UpdateDb: boolean = true): void {



        this._fuseConfigService.config = { theme };

        if (UpdateDb && this.user != null) {

            this.user.theme = theme;


            this._commonService.GetTheme(theme).forEach(t => {

                this.user._Theme = t;

                localStorage.setItem('theme', theme);
                localStorage.setItem('title', this.user._Theme.title);
                localStorage.setItem('logo.text', this.user._Theme.logoTextPath);
                localStorage.setItem('logo.normal', this.user._Theme.logoPath);
                localStorage.setItem('logo.dark', this.user._Theme.logoDarkPath);
            });



            // Update the user
            this._userService.update({
                ...this.user
            }).subscribe();


            // Subscribe to config changes
            this._fuseConfigService.config$
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe((config: AppConfig) => {

                    // Store the config
                    this.config = config;
                });


            this._updateTheme();
            this._changeDetectorRef.markForCheck();
        }
    }

    /**
     * Update the selected layout
     */
    private _updateLayout(): void {
        // Get the current activated route
        let route = this._activatedRoute;
        while (route.firstChild) {
            route = route.firstChild;
        }

        // 1. Set the layout from the config
        this.layout = this.config.layout;

        // 2. Get the query parameter from the current route and
        // set the layout and save the layout to the config
        const layoutFromQueryParam = (route.snapshot.queryParamMap.get('layout') as Layout);
        if (layoutFromQueryParam) {
            this.layout = layoutFromQueryParam;
            if (this.config) {
                this.config.layout = layoutFromQueryParam;
            }
        }

        // 3. Iterate through the paths and change the layout as we find
        // a config for it.
        //
        // The reason we do this is that there might be empty grouping
        // paths or componentless routes along the path. Because of that,
        // we cannot just assume that the layout configuration will be
        // in the last path's config or in the first path's config.
        //
        // So, we get all the paths that matched starting from root all
        // the way to the current activated route, walk through them one
        // by one and change the layout as we find the layout config. This
        // way, layout configuration can live anywhere within the path and
        // we won't miss it.
        //
        // Also, this will allow overriding the layout in any time so we
        // can have different layouts for different routes.
        const paths = route.pathFromRoot;
        paths.forEach((path) => {

            // Check if there is a 'layout' data
            if (path.routeConfig && path.routeConfig.data && path.routeConfig.data.layout) {
                // Set the layout
                this.layout = path.routeConfig.data.layout;
            }
        });
    }

    /**
     * Update the selected scheme
     *
     * @private
     */
    private _updateScheme(): void {
        // Remove class names for all schemes
        this._document.body.classList.remove('light', 'dark');

        // Add class name for the currently selected scheme
        this._document.body.classList.add(this.scheme);
    }

    /**
     * Update the selected theme
     *
     * @private
     */
    private _updateTheme(): void {
        // Find the class name for the previously selected theme and remove it
        this._document.body.classList.forEach((className: string) => {
            if (className.startsWith('theme-')) {
                this._document.body.classList.remove(className, className.split('-')[1]);
            }
        });

        // Add class name for the currently selected theme
        this._document.body.classList.add(this.theme);
    }

}
