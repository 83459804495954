<ng-container *ngIf="show">

<div class="center">
    <span>Identitätswechsel aktiviert</span>
    <div class="userinfo">
        <strong>Login Benutzer: </strong>
        <span>{{user.originalEmail}}</span>
    </div>
</div>

</ng-container>
