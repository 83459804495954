<!-- Shortcuts toggle -->
<button mat-icon-button
        (click)="openPanel()" [matTooltip]="'Code verwenden'"
        #codeOrigin>
    <mat-icon [svgIcon]="'heroicons_outline:key'"></mat-icon>
</button>



<!-- Shortcuts panel -->
<ng-template #codePanel>




    <div class="fixed inset-0 sm:static sm:inset-auto flex flex-col sm:min-w-90 sm:w-90 sm:rounded-2xl overflow-hidden shadow-lg">

        <!-- Header -->
        <div class="flex shrink-0 items-center py-4 pr-4 pl-4  bg-gray-600 text-on-primary">



            <div class="w-full">
                <form [formGroup]="codeForm">
                    <mat-form-field class="fuse-mat-no-subscript w-full mt-1"
                                    [floatLabel]="'always'">
                        <input class="code-input" [formControlName]="'code'" matInput [textMask]="{mask: mask}" [placeholder]="'XXXXXX-XXXXXX-XXXXXX'" />
                        <button mat-icon-button
                                [disabled]="!codeForm.valid"
                                matSuffix (click)="submitCode()">
                            <mat-icon class="icon-size-5"
                                      [svgIcon]="'heroicons_solid:check'"></mat-icon>
                        </button>
                    </mat-form-field>
                </form>
            </div>
        </div>
    </div>
</ng-template>
