<!-- toggle -->
<button mat-icon-button   [matTooltip]="'Identitätswechsel'"
        (click)="openPanel()"
        #impersonationOrigin>
    <mat-icon *ngIf="impersonationActive" [svgIcon]="'heroicons_outline:eye-off'" class="text-red-500"></mat-icon>
    <mat-icon *ngIf="!impersonationActive"[svgIcon]="'heroicons_outline:eye'"></mat-icon>
</button >




<!-- Shortcuts panel -->
<ng-template #impersonationPanel>
    <div class="fixed inset-0 sm:static sm:inset-auto flex flex-col sm:min-w-90 sm:w-90 sm:rounded-2xl overflow-hidden shadow-lg">

        <!-- Header -->
        <div class="flex shrink-0 items-center py-4 pr-4 pl-6 bg-primary-600 text-on-primary">
            <div class="sm:hidden -ml-1 mr-3">
                <button mat-icon-button
                        (click)="closePanel()">
                    <mat-icon class="icon-size-5 text-current"
                              [svgIcon]="'heroicons_solid:x'"></mat-icon>
                </button>
            </div>
            <div class="flex text-lg font-medium leading-10">
                <span class="">Identitätswechsel      </span>
                <span class="ml-1" *ngIf="impersonationActive"> - Aktiv</span>
            </div>

            <div class="ml-auto">

                <!-- View mode -->
                <ng-container>
                    <!-- Enter 'add' mode -->
                    <button *ngIf="impersonationActive"
                            mat-icon-button
                            (click)="cancelImpersonation()"
                            [matTooltip]="'Beenden'">
                        <mat-icon class="icon-size-5 text-current"
                                  [svgIcon]="'heroicons_solid:x-circle'"></mat-icon>
                    </button>
                </ng-container>

            </div>
        </div>

        <div class="relative  bg-card">


            <form [formGroup]="impersonationForm" class="p-3">
                <mat-form-field class="fuse-mat-no-subscript w-full mt-1"
                                [floatLabel]="'always'" [placeholder]="'Benutzer suchen'">
                    <input [formControlName]="'search'" matInput />
                    <button mat-icon-button
                            [disabled]="!impersonationForm.valid"
                            matSuffix (click)="searchUser()">
                        <mat-icon class="icon-size-5"
                                  [svgIcon]="'heroicons_solid:search'"></mat-icon>
                    </button>
                </mat-form-field>


            </form>

            <!-- View mode -->
            <ng-container *ngIf="impersonationUsers">



                <!-- Shortcuts -->
                <div class="grid grid-cols-1 grid-flow-row flex flex-col flex-auto sm:max-h-90 -mb-px overflow-y-auto">




                    <!-- Shortcut -->
                    <ng-container *ngFor="let impersonationUser of impersonationUsers; trackBy: trackByFn">
                        <div class="relative group flex flex-col overflow-hidden bg-card border-r border-b even:border-r-0 hover:bg-gray-50 dark:hover:bg-black dark:hover:bg-opacity-5">

                            <ng-container>
                                <div class="absolute inset-0 z-99 cursor-pointer"
                                     (click)="selectUser(impersonationUser)">
                                </div>
                            </ng-container>
                            <!-- Normal links -->
                            <a class="flex flex-col items-center justify-center w-full h-full py-3 no-underline">
                                <div class="font-medium text-center">{{impersonationUser.email}}</div>
                                <div class="text-md text-center text-secondary">{{impersonationUser.id}}</div>
                            </a>

                        </div>
                    </ng-container>
                </div>

                <!-- No shortcuts -->
                <ng-container *ngIf="!impersonationUsers || !impersonationUsers.length">
                    <div class="flex flex-col flex-auto items-center justify-center sm:justify-start py-12 px-8">
                        <div class="flex flex-0 items-center justify-center w-14 h-14 rounded-full bg-primary-100 dark:bg-primary-600">
                            <mat-icon class="text-primary-700 dark:text-primary-50"
                                      [svgIcon]="'heroicons_outline:users'"></mat-icon>
                        </div>
                        <div class="mt-5 text-2xl font-semibold tracking-tight">Keine Benutzer</div>
                        <div class="w-full max-w-60 mt-1 text-md text-center text-secondary">Es wurden keine Benutzer für den Identitätswechsel gefunden.</div>
                    </div>
                </ng-container>
            </ng-container>


        </div>
    </div>
</ng-template>
