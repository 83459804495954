import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, NavigationEnd, NavigationStart, Resolve, ResolveEnd, ResolveStart, Router, RouterStateSnapshot } from '@angular/router';
import { filter, forkJoin, Observable } from 'rxjs';
import { NavigationService } from 'app/core/navigation/navigation.service';
import { NotificationsService } from 'app/layout/common/notifications/notifications.service';
import { ShortcutsModule } from 'app/layout/common/shortcuts/shortcuts.module';
import { CodeService } from 'app/layout/common/code/code.service';
import { UserService } from './core/user/user.service';
import { isUndefined } from 'lodash';
import { TeamChatService } from './layout/common/team-chat/team-chat.service';
import { ShortcutsService } from './layout/common/shortcuts/shortcuts.service';
import { ImpersonationService } from './layout/common/impersonation/impersonation.service';
import { OrganizationService } from './core/organization/organization.service';

@Injectable({
    providedIn: 'root'
})
export class InitialDataResolver implements Resolve<any>
{
    /**
     * Constructor
     */
    constructor(
        private _navigationService: NavigationService,
        private _notificationsService: NotificationsService,
        private _teamChatService: TeamChatService,
        private _shortcutsService: ShortcutsService,
        private _organizationService: OrganizationService,
        private _impersonationService: ImpersonationService,
        private router: Router,
        private _userService: UserService
    ) {

        this.router.events
            .pipe(
                // You can also use traditional if else in the subscribe 
                filter(event => event instanceof ResolveEnd)
            )
            .subscribe(event => {



                var _url: string = (<any>event).url;

                if (_url.length >= 1 && !document.location.href.endsWith(_url.substring(1, _url.length)))
                    this._userService.logActivity('navigation', _url);
            });
    }


    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Use this resolver to resolve initial mock-api for the application
     *
     * @param route
     * @param state
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
        // Fork join multiple API endpoint calls to wait all of them to finish
        return forkJoin([
            this._navigationService.get(),
            this._notificationsService.getAll(),
            this._shortcutsService.getAll(),
            this._teamChatService.getTeamMembers(),
            this._teamChatService.getTeam(),
            this._organizationService.getUserOrganizationList(),
            this._organizationService.getMembers(),
            this._organizationService.getActive(),
            this._userService.getFunction()
        ]);
    }
}
