import { Route } from '@angular/router';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { InitialDataResolver } from 'app/app.resolvers';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [

    // Redirect empty path to '/example'
    { path: '', pathMatch: 'full', redirectTo: 'home' },

    // Redirect signed in user to the '/example'
    //
    // After the user signs in, the sign in page will redirect the user to the 'signed-in-redirect'
    // path. Below is another redirection for that path to redirect the user to the desired
    // location. This is a small convenience to keep all main routes together here on this file.
    { path: 'signed-in-redirect', pathMatch: 'full', redirectTo: 'home' },

    // Auth routes for guests
    {
        path: '',
        canActivate: [NoAuthGuard],
        canActivateChild: [NoAuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            { path: 'confirmation-required', loadChildren: () => import('app/modules/auth/confirmation-required/confirmation-required.module').then(m => m.AuthConfirmationRequiredModule) },
            { path: 'confirm-email', loadChildren: () => import('app/modules/auth/confirm-email/confirm-email.module').then(m => m.AuthConfirmEmailModule) },
            { path: 'forgot-password', loadChildren: () => import('app/modules/auth/forgot-password/forgot-password.module').then(m => m.AuthForgotPasswordModule) },
            { path: 'reset-password', loadChildren: () => import('app/modules/auth/reset-password/reset-password.module').then(m => m.AuthResetPasswordModule) },
            { path: 'sign-in', loadChildren: () => import('app/modules/auth/sign-in/sign-in.module').then(m => m.AuthSignInModule) },
            { path: 'sign-up', loadChildren: () => import('app/modules/auth/sign-up/sign-up.module').then(m => m.AuthSignUpModule) }
        ]
    },

    // Landing routes
   /* {
        path: 'home',
        canActivate: [NoAuthGuard],
        canActivateChild: [NoAuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            { path: 'home', loadChildren: () => import('app/modules/landing/home/home.module').then(m => m.LandingHomeModule) },
        ]
    },*/

    // Auth routes for authenticated users
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            { path: 'sign-out', loadChildren: () => import('app/modules/auth/sign-out/sign-out.module').then(m => m.AuthSignOutModule) },
            { path: 'no-permission', loadChildren: () => import('app/modules/auth/no-permission/no-permission.module').then(m => m.AuthNoPermissionModule) },
            { path: 'unlock-session', loadChildren: () => import('app/modules/auth/unlock-session/unlock-session.module').then(m => m.AuthUnlockSessionModule) }
        ]
    },



    // Admin routes
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: InitialDataResolver,
        },
        children: [
            { path: 'home', loadChildren: () => import('app/modules/landing/home/home.module').then(m => m.HomeModule) },


            // Dashboards
            {
                path: 'dashboards', children: [
                    { path: 'marketing', loadChildren: () => import('app/modules/admin/dashboards/marketing/marketing.module').then(m => m.MarketingModule) },
                    { path: 'report', loadChildren: () => import('app/modules/admin/dashboards/report/report.module').then(m => m.ReportModule) },
                    { path: 'technology-research-hub', loadChildren: () => import('app/modules/admin/dashboards/technology-research-hub/technology-research-hub.module').then(m => m.TechnologyReasearchHubModule) },
                    { path: 'intentdata', loadChildren: () => import('app/modules/admin/dashboards/intentdata/intentdata.module').then(m => m.IntentDataModule) },
                    { path: 'campaign-viewer', loadChildren: () => import('app/modules/admin/dashboards/campaign-viewer/campaign-viewer.module').then(m => m.CampaignViewerModule) },
                    /*  { path: 'autonachversand', loadChildren: () => import('app/modules/admin/dashboards/autonachversand/autonachversand.module').then(m => m.AutonachversandModule) },*/
                ]


            },

            //Pages
            { path: 'prospectviewer', loadChildren: () => import('app/modules/admin/pages/prospectviewer/prospectviewer.module').then(m => m.ProspectViewerModule) },
            // { path: 'prospectviewer/companies', loadChildren: () => import('app/modules/admin/pages/prospectviewer/prospectviewer.module').then(m => m.ProspectViewerModule) },
            // { path: 'campaigns', loadChildren: () => import('app/modules/admin/pages/campaigns/campaign.module').then(m => m.CampaignModule) },
            { path: 'settings', loadChildren: () => import('app/modules/admin/pages/settings/settings.module').then(m => m.SettingsModule) },
            // { path: 'test', loadChildren: () => import('app/modules/admin/pages/test/ecommerce.module').then(m => m.ECommerceModule) },
            //  { path: 'prospectviewer', loadChildren: () => import('app/modules/admin/apps/prospectviewer/prospectviewer.module').then(m => m.ProspectViewerModule) }
        ]
    }
];
