import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from 'app/shared/shared.module';
import { ImpersonationBarComponent } from './impersonation-bar.component';

@NgModule({
    declarations: [
        ImpersonationBarComponent
    ],
    imports: [
        RouterModule,
        SharedModule
    ],
    exports: [
        ImpersonationBarComponent
    ]
})
export class ImpersonationBarModule {
}
