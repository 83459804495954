/* tslint:disable:max-line-length */
import { FuseNavigationItem } from '@fuse/components/navigation';

export const defaultNavigation: FuseNavigationItem[] = [
    {
        id: 'dashboards',
        title: 'Dashboards',
        subtitle: null,
        type: 'group',
        icon: 'heroicons_outline:home',
        children: [
           {
                id: 'dashboards.marketing',
                title: 'Marketing',
                type: 'basic',
                icon: 'heroicons_outline:cursor-click',
                link: '/dashboards/marketing'
            },
            {
                id: 'dashboards.report',
                title: 'Report Center',
                type: 'basic',
                icon: 'heroicons_outline:presentation-chart-bar',
                link: '/dashboards/report'
            },
            {
                id: 'dashboards.technology-research-hub',
                title: 'Technology Research Hub',
                type: 'basic',
                icon: 'heroicons_outline:presentation-chart-bar',
                link: '/dashboards/technology-research-hub'
            }, {
                id: 'dashboards.campaign-viewer',
                title: 'Campaign Viewer',
                type: 'basic',
                icon: 'heroicons_outline:presentation-chart-bar',
                link: '/dashboards/campaign-viewer'
            },
           /* {
                id: 'dashboards.autonachversand',
                title: 'Autonachversand',
                type: 'basic',
                icon: 'heroicons_outline:chart-pie',
                link: '/dashboards/autonachversand'
            },*/
        ]
    },
   {
        id: 'applications',
        title: 'Anwendungen',
        subtitle: null,
        type: 'group',
        icon: 'heroicons_outline:home',
        children: [
           {
                id: 'web-tracking',
                title: 'Webseiten Tracking',
                type: 'basic',
                icon: 'heroicons_outline:finger-print',
                link: '/web-tracking'
            }/*
            {
                id: 'campaigns',
                title: 'Kampagnen',
                type: 'basic',
                icon: 'heroicons_outline:chart-pie',
                link: '/campaigns'
            },*/
           
        ]
    }
];
export const compactNavigation: FuseNavigationItem[] = [
    {
        id: 'dashboards',
        title: 'Dashboards',
        subtitle: null,
        type: 'group',
        icon: 'heroicons_outline:home',
        children: [
            {
                id: 'dashboards.marketing',
                title: 'Marketing',
                type: 'basic',
                icon: 'heroicons_outline:chart-pie',
                link: '/dashboards/marketing'
            },

        ]
    },
    {
        id: 'applications',
        title: 'Anwendungen',
        subtitle: null,
        type: 'group',
        icon: 'heroicons_outline:home',
        children: [
            {
                id: 'example',
                title: 'Leere Seite',
                type: 'basic',
                icon: 'heroicons_outline:chart-pie',
                link: '/example'
            },
            {
                id: 'campaigns',
                title: 'Kampagnen',
                type: 'basic',
                icon: 'heroicons_outline:chart-pie',
                link: '/campaigns'
            },
            {
                id: 'prospectviewer',
                title: 'Prospect Viewer',
                type: 'basic',
                icon: 'heroicons_outline:chart-pie',
                link: '/prospectviewer'
            },
        ]
    }
];
export const futuristicNavigation: FuseNavigationItem[] = [
    {
        id: 'dashboards',
        title: 'Dashboards',
        subtitle: null,
        type: 'group',
        icon: 'heroicons_outline:home',
        children: [
            {
                id: 'dashboards.marketing',
                title: 'Marketing',
                type: 'basic',
                icon: 'heroicons_outline:chart-pie',
                link: '/dashboards/marketing'
            },

        ]
    },
    {
        id: 'applications',
        title: 'Anwendungen',
        subtitle: null,
        type: 'group',
        icon: 'heroicons_outline:home',
        children: [
            {
                id: 'example',
                title: 'Leere Seite',
                type: 'basic',
                icon: 'heroicons_outline:chart-pie',
                link: '/example'
            },
            {
                id: 'campaigns',
                title: 'Kampagnen',
                type: 'basic',
                icon: 'heroicons_outline:chart-pie',
                link: '/campaigns'
            },
            {
                id: 'prospectviewer',
                title: 'Prospect Viewer',
                type: 'basic',
                icon: 'heroicons_outline:chart-pie',
                link: '/prospectviewer'
            },
        ]
    }
];
export const horizontalNavigation: FuseNavigationItem[] = [
    {
        id: 'dashboards',
        title: 'Dashboards',
        subtitle: null,
        type: 'group',
        icon: null,
        children: [
            {
                id: 'dashboards.marketing',
                title: 'Marketing',
                type: 'basic',
                icon: 'heroicons_outline:chart-pie',
                link: '/dashboards/marketing'
            },

        ]
    },
    {
        id: 'applications',
        title: 'Anwendungen',
        subtitle: null,
        type: 'group',
        icon: null,
        children: [
            {
                id: 'example',
                title: 'Leere Seite',
                type: 'basic',
                icon: 'heroicons_outline:chart-pie',
                link: '/example'
            },
            {
                id: 'campaigns',
                title: 'Kampagnen',
                type: 'basic',
                icon: 'heroicons_outline:chart-pie',
                link: '/campaigns'
            },
            {
                id: 'prospectviewer',
                title: 'Prospect Viewer',
                type: 'basic',
                icon: 'heroicons_outline:chart-pie',
                link: '/prospectviewer'
            },
        ]
    }
];
