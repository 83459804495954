import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { catchError, map, Observable, of, switchMap, throwError, tap, ReplaySubject, take } from 'rxjs';
import { ChangePasswordResult, LogActivity, User, UserFunction } from 'app/core/user/user.types';
import { AvailableLangs, TranslocoService } from '@ngneat/transloco';
import { CommonService } from '../common/common.service';
import { DOCUMENT } from '@angular/common';
import { isUndefined } from 'lodash';


@Injectable({
    providedIn: 'root'
})


export class UserService {

    private _user: ReplaySubject<User> = new ReplaySubject<User>(1);
    private _userFunction: ReplaySubject<UserFunction> = new ReplaySubject<UserFunction>(1);
    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient, private _trancslocoService: TranslocoService, private _commonService: CommonService) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for user
     *
     * @param value
     */
    set user(user: User) {
        // Store the value
        this._user.next(user);


        if (user.language != null) {
            this._trancslocoService.setActiveLang(user.language)
        }
        //  this._languageComponent.setActiveLang(user.language);

    }
    get userFunctions$(): Observable<UserFunction> {
        return this._userFunction.asObservable();
    }
    get user$(): Observable<User> {
        return this._user.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get the current logged in user data
     */
    get(): Observable<User> {



        return this._httpClient.get<User>('api/User/Data').pipe(
            tap((user) => {
                this._user.next(user);
            })
        );
    }


    getFunction(): Observable<UserFunction> {



        return this._httpClient.get<UserFunction>('api/User/Functions').pipe(
            tap((userFunction) => {
                this._userFunction.next(userFunction);
            })
        );
    }

    /**
     * Update the user
     *
     * @param user
     */
    update(user: User): Observable<User> {
        return this._httpClient.patch<User>('api/User/Data', user).pipe(
            map((updateUser: User) => {
                this._user.next(updateUser);
                return updateUser;
            })
        )
    };


    changePassword(password: string): Observable<ChangePasswordResult> {

        const params = new HttpParams()
            .set('password', password);

        return this._httpClient.patch<ChangePasswordResult>('api/User/ChangePassword', null, { params }).pipe(
            map((changePasswordResult: ChangePasswordResult) => {
                return changePasswordResult;
            })
        )
    };




    setStatus(status: string) {

        const params = new HttpParams()
            .set('status', status);

        this._httpClient.post('api/User/Status', null, { params }).subscribe();

    }


    logActivity(typeName: string, addText?: string, ref_id?: any, addValue?: any) {


        const params = new HttpParams()
            .set('typeName', typeName)
            .set('addText', addText)
            .set('refID', (<string>ref_id))
            .set('addValue', (<string>addValue))
            .set('url', document.location.href);

        console.log(params);

        this._httpClient.post('api/User/LogActivity', null, { params }).subscribe();

    }
}
