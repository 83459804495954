import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, CanLoad, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { Observable, of, switchMap } from 'rxjs';
import { AuthService } from 'app/core/auth/auth.service';
import { isNull } from 'lodash';
import { UserService } from '../../user/user.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {
    /**
     * Constructor
     */
    constructor(
        private _authService: AuthService,
        private _router: Router,
        private _userService: UserService
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Can activate
     *
     * @param route
     * @param state
     */
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        const redirectUrl = state.url === '/sign-out' ? '/' : state.url;
        const role = route.data.role ?? null;
        const permission = route.data.permission ?? null;
        return this._check(redirectUrl, role, permission);
    }

    /**
     * Can activate child
     *
     * @param childRoute
     * @param state
     */
    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        const redirectUrl = state.url === '/sign-out' ? '/' : state.url;
        const role = childRoute.data.role ?? null;
        const permission = childRoute.data.permission ?? null;
        return this._check(redirectUrl, role, permission);
    }

    /**
     * Can load
     *
     * @param route
     * @param segments
     */
    canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
        const role = route.data.role ?? null;
        const permission = route.data.permission ?? null;
        return this._check('/', role, permission);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Check the authenticated status
     *
     * @param redirectURL
     * @private
     */
    private _check(redirectURL: string, role: string, permission: string): Observable<boolean> {


        // Check the authentication status
        return this._authService.check()
            .pipe(
                switchMap((authenticated) => {


                    // If the user is not authenticated...
                    if (!authenticated) {
                        // Redirect to the sign-in page
                        this._router.navigate(['sign-in'], { queryParams: { redirectURL } });

                        // Prevent the access
                        return of(false);
                    } else {


                        if (!isNull(role)) {
                            this._authService.hasRole(role).subscribe(hasRole => {

                                if (hasRole == false) {
                                    this._router.navigate(['no-permission']);
                                    return of(false);
                                }
                            });
                        } else if (!isNull(permission)) {
                            this._authService.hasPermission(permission).subscribe(hasPermission => {
                                if (hasPermission == false) {
                                    this._router.navigate(['no-permission']);
                                    return of(false);
                                }
                            });
                        }
                    }

                    // Allow the access
                    return of(true);
                })
            );
    }
}
