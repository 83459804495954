import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { merge } from 'lodash-es';
import { Subject, map, tap, takeUntil } from 'rxjs';
import { FUSE_APP_CONFIG } from '@fuse/services/config/config.constants';
import { CommonService } from '../../../app/core/common/common.service';
import { CustomTheme } from '../../../app/core/common/common.types';
import { Themes } from '../../../app/core/config/app.config';

@Injectable({
    providedIn: 'root'
})
export class FuseConfigService
{
    private _config: BehaviorSubject<any>;

    private _unsubscribeAll: Subject<any> = new Subject<any>();
    /**
     * Constructor
     */
    constructor(@Inject(FUSE_APP_CONFIG) config: any, private _commonService: CommonService)
    {



        // Private
        this._config = new BehaviorSubject(config);




    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for config
     */
    set config(value: any)
    {



        // Merge the new config over to the current config
        const config = merge({}, this._config.getValue(), value);



        // Execute the observable
        this._config.next(config);
    }

    // eslint-disable-next-line @typescript-eslint/member-ordering
    get config$(): Observable<any>
    {
       



        return this._config.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Resets the config to the default
     */
    reset(): void
    {
        // Set the config



     

        this._config.next(this.config);


    }
}
