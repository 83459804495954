import { Injectable, NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatMenuModule } from '@angular/material/menu';
import { UserComponent } from 'app/layout/common/user/user.component';
import { SharedModule } from 'app/shared/shared.module';
import { MatSelectModule } from '@angular/material/select';
import { MatSliderModule } from '@angular/material/slider';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { TextMaskModule } from 'angular2-text-mask'
import { FuseDrawerModule } from '../../../../@fuse/components/drawer';
import { DesignComponent } from '../design/design.component';
import { MatTooltip, MatTooltipModule } from '@angular/material/tooltip';
        


@NgModule({
    declarations: [
        UserComponent,
        DesignComponent
        
    ],
    imports     : [
        MatButtonModule,
        MatDividerModule,
        MatIconModule,
        MatSelectModule,
        MatSliderModule,
        MatInputModule,
        MatMenuModule,
        MatTooltipModule,
        TextMaskModule,
        FuseDrawerModule,
        SharedModule,
        FuseDrawerModule,
        MatIconModule,
    ],
    exports     : [
        UserComponent,
        DesignComponent
        
    ], providers: [DesignComponent]
   
})
export class UserModule
{
}
