import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map, Observable, ReplaySubject, switchMap, take, tap } from 'rxjs';
import { ImpersonationUser } from './impersonation.types';

@Injectable({
    providedIn: 'root'
})
export class ImpersonationService {

    private _impersonationUsers: ReplaySubject<ImpersonationUser[]> = new ReplaySubject<ImpersonationUser[]>(1);

    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     */
    get impersonationUsers$(): Observable<ImpersonationUser[]> {
        return this._impersonationUsers.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get all messages
     */
    getImpersonationUsers(): Observable<ImpersonationUser[]> {


        return this._httpClient.get<ImpersonationUser[]>('api/User/Impersonation/Users').pipe(
            tap((impersonationUser) => {
                this._impersonationUsers.next(impersonationUser);
            })
        );
    }


    searchImpersonationUsers(search: string): Observable<ImpersonationUser[]> {

            const params = new HttpParams()
                .set('search', search);

        return this._httpClient.get<ImpersonationUser[]>('api/User/Impersonation/Users', { params }).pipe(
            tap((impersonationUser) => {
                this._impersonationUsers.next(impersonationUser);
            })
        );
    }


    cancel() {
        const params = new HttpParams();
        this._httpClient.post('api/User/Impersonation/Cancel', null, { params }).subscribe();
    }

    start(id: string) {
        const params = new HttpParams()
            .set('ImpersonationUserId', id);

        this._httpClient.post('api/User/Impersonation/Start', null, { params }).subscribe();
    }
}
