import { CustomTheme } from "../common/common.types";

export interface OrganizationListData
{
    organizationId: number;
    organizationName: string;
    organizationRole: string;
    active: boolean;
    canEdit: boolean;
    canDelete: boolean;
}

export interface Organization {
    id: number;
    name: string;
    street: string;
    postalcode: string;
    city: string;
    website: string;
    phoneNumber: string;
    email: string;
    active: boolean;
    canEdit: boolean;
    canDelete: boolean;
    logoDataImage: string;
}


export interface OrganizationMember {
    id: string;
    name: string;
    email: string;
    jobRole: string;
    role: string;
    avatar: string;
}


export interface InviteMemberResult {
    success: boolean;
    errorCode: string;
}
