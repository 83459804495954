import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import { AuthService } from 'app/core/auth/auth.service';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { Router } from '@angular/router';

@Injectable()
export class AuthInterceptor implements HttpInterceptor
{
    /**
     * Constructor
     */
    constructor(private _authService: AuthService, private _router: Router)
    {
    }

    /**
     * Intercept
     *
     * @param req
     * @param next
     */
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>
    {
        // Clone the request object
        let newReq = req.clone();

        // Request
        //
        // If the access token didn't expire, add the Authorization header.
        // We won't add the Authorization header if the access token expired.
        // This will force the server to return a "401 Unauthorized" response
        // for the protected API routes which our response interceptor will
        // catch and delete the access token from the local storage while logging
        // the user out from the app.
        if ( this._authService.accessToken && !AuthUtils.isTokenExpired(this._authService.accessToken) )
        {
            newReq = req.clone({
                headers: req.headers.set('Authorization',  this._authService.accessToken)
            });
        }

        // Response
        return next.handle(newReq).pipe(
            catchError((error) => {

                console.error(error);

                // Catch "401 Unauthorized" responses
                if ( error instanceof HttpErrorResponse && error.status === 401 )
                {

                    if (error.url.endsWith('/api/Authorize/SignIn') && error.error == 'wrong_credentials') {
                        return throwError("Ungültiger Benutzername oder Passwort");
                    }


                    if (error.url.endsWith('/api/Authorize/SignIn') && error.error == 'user_not_confirmed') {

                        const redirectURL =  '/confirmation-required';

                        // Navigate to the redirect url
                        this._router.navigateByUrl(redirectURL);


                        return throwError("Bitte bestätige deine E-Mail Adresse, bevor du dich einloggen kannst.");
                    }

                    if (error.url.endsWith('/api/Authorize/SignIn') && error.error == 'no_permission') {
                        return throwError("Sie haben keine Berechtigung, um das Scalooper Dashboard nutzen zu können.");
                    }

                    if (error.url.endsWith('/api/Authorize/SignIn') && error.error == 'service_not_available') {
                        return throwError("Der Server zur Authentifizierung steht derzeit nicht zur Verfügung. Bitte versuchen Sie es später erneut.");
                    }
                    // Sign out
                    this._authService.signOut();

                    // Reload the app
                    location.reload();
                }

                if (error instanceof HttpErrorResponse && error.status === 504) {


                    if (error.url.endsWith('/api/Authorize/SignIn')) {
                        return throwError("Die API-Schnittstelle antwortet nicht. Bitte versuchen Sie es später erneut oder kontaktieren Sie Ihren Ansprechpartner.");
                    }

                    // Sign out
                    this._authService.signOut();

                    // Reload the app
                    location.reload();
                }


                return throwError(error);
            })
        );
    }
}
