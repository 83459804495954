import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable, ReplaySubject, tap } from 'rxjs';
import { Country, CustomTheme } from 'app/core/common/common.types';

@Injectable({
    providedIn: 'root'
})
export class CommonService {

    private _countries: ReplaySubject<Country[]> = new ReplaySubject<Country[]>(1);
    private _themes: ReplaySubject<CustomTheme[]> = new ReplaySubject<CustomTheme[]>(1);
    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient) {
    }




    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------


    /**
      * Get all countries
      */
    GetCountries(languageIso2?: string): Observable<Country[]> {
        return this._httpClient.get<Country[]>('api/Common/Countries?languageIso2=' + languageIso2 ?? 'de').pipe(
            tap((country) => {
                this._countries.next(country);
            })
        );
    }

    GetThemes(): Observable<CustomTheme[]> {
        return this._httpClient.get<CustomTheme[]>('api/Common/Themes').pipe(
            tap((theme) => {
                this._themes.next(theme);
            })
        );
    }

    GetTheme(ThemeName?: string): Observable<CustomTheme> {
        return this._httpClient.get<CustomTheme>('api/Common/Theme?themeName=' + ThemeName ?? 'theme-scalooper').pipe(
            tap((theme) => {
                return theme;
            })
    );
    }
}
