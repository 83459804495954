<impersonation-bar *ngIf="impersonationActive"></impersonation-bar>

<!-- ----------------------------------------------------------------------------------------------------- -->
<!-- Empty layout -->
<!-- ----------------------------------------------------------------------------------------------------- -->
<empty-layout *ngIf="layout === 'empty'"></empty-layout>
<!-- ----------------------------------------------------------------------------------------------------- -->
<!-- Layouts with horizontal navigation -->
<!-- ----------------------------------------------------------------------------------------------------- -->
<!-- Centered -->
<centered-layout *ngIf="layout === 'centered'" [ngClass]="{'pt-12': impersonationActive}"></centered-layout>
<!--<enterprise-layout *ngIf="layout === 'enterprise'"></enterprise-layout>
<material-layout *ngIf="layout === 'material'"></material-layout>
<modern-layout *ngIf="layout === 'modern'"></modern-layout>-->
<!-- ----------------------------------------------------------------------------------------------------- -->
<!-- Layouts with vertical navigation -->
<!-- ----------------------------------------------------------------------------------------------------- -->
<!-- Classic -->
<classic-layout *ngIf="layout === 'classic'"  [ngClass]="{'pt-12': impersonationActive}"></classic-layout>
<classy-layout *ngIf="layout === 'classy'" [ngClass]="{'pt-12': impersonationActive}"></classy-layout>
<!-- Classy -->
<!---->
<compact-layout *ngIf="layout === 'compact'" [ngClass]="{'pt-12': impersonationActive}"></compact-layout>
<!--<dense-layout *ngIf="layout === 'dense'"></dense-layout>-->
<futuristic-layout *ngIf="layout === 'futuristic'" [ngClass]="{'pt-12': impersonationActive}"></futuristic-layout>
<!--<thin-layout *ngIf="layout === 'thin'"></thin-layout>-->
