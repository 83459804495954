import { UserModule } from "../../layout/common/user/user.module";
import { CustomTheme } from "../common/common.types";

export interface User
{
    userId: string;
    originalUserId: string;
    originalEmail: string;
    impersonationActive: boolean;
    firstname: string;
    lastname: string
    email: string;
    fullname: string;
    salutation: string;
    company: string;
    status: string;
    lastStatus: string;
    avatar: string;
    language: string;
    scheme: string;
    layout: string;
    theme: string;
    address?: UserAddress;
    jobRole: string;
    phone: string;
    _Theme: CustomTheme;
}


export interface UserAddress {
    street: string;
    city: string;
    postal_code: string;
    country: string;
}

export interface CI_Settings {
    theme: string;
    title: string;
    logo: CI_Settings_Logo;
}

export interface CI_Settings_Logo {
    normal: string;
    dark: string;
    text: string;
}

export interface LogActivity {
    url: string;
    addText: string;
    typeName: string;
}


export interface ChangePasswordResult {
    success: boolean;
    errorCodes: Array<string>
}


export interface UserFunction {
    impersonation: boolean;
    userActivity: boolean;
    changeTheme: boolean;
    campaignViewerConfiguration: boolean;
    userModules: UserModules;
}

export interface UserModules {
    technologyResearchHub: boolean;
    campaignViewer: boolean;
    marketingViewer: boolean;
    reportCenter;
}
