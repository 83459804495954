/* eslint-disable */
export const shortcuts = [
    
    {
        id         : '3c48e75e-2ae7-4b73-938a-12dc655be28b',
        label      : 'Dashboard',
        description: 'Markerting',
        link       : '/dashboards/marketing',
        useRouter  : true
    }
    ,
    {
        id: '3c48e75e-2ae7-4b73-938a-12dc655be22b',
        label: 'TRH',
        description: 'Reporting',
        link: '/dashboards/report/4e9713b1-df72-472b-a7fb-62f8c5438601',
        useRouter: true
    }
];
