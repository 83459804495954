<!-- Button -->
<button mat-icon-button
        [matMenuTriggerFor]="userActions">
    <span class="relative">
        <img class="w-7 h-7 rounded-full"
             *ngIf="showAvatar && user.avatar"
             [src]="user.avatar">
        <mat-icon *ngIf="!showAvatar || !user.avatar"
                  [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
        <span class="absolute bottom-0 right-0 flex-0 w-2 h-2 -ml-0.5 rounded-full ring-2 ring-bg-card dark:ring-gray-900 text-on-primary "
              [ngClass]="{'mr-px mb-px': !showAvatar || !user.avatar,
                          'bg-green-500': user.status === 'online',
                          'bg-amber-500': user.status === 'away',
                          'bg-red-500': user.status === 'busy',
                          'bg-gray-400': user.status === 'not-visible' || user.status === 'offline'}"></span>






    </span>
</button>

<design></design>


<mat-menu [xPosition]="'before'"
          #userActions="matMenu">
    <button mat-menu-item>
        <span class="flex flex-col leading-none">
            <span class="text-s">Eingeloggt als</span>
            <span class="mt-1.5 text-md font-medium">{{user.email}}</span>
        </span>
    </button>

    <mat-divider *ngIf="organizationCount > 1" class="my-2"></mat-divider>


    <button mat-menu-item (click)="organization()" [matMenuTriggerFor]="organisationsMenu" [matTooltip]="'Aktuelle Organisation'" *ngIf="organizationCount > 1">
        <mat-icon [svgIcon]="'heroicons_outline:office-building'"></mat-icon>
        <div>
            {{organizationName}}
            <span class="role-info" *ngIf="organizationRole === 'administrator'">Administrator</span>
            <span class="role-info" *ngIf="organizationRole === 'creator'">Ersteller</span>
            <span class="role-info" *ngIf="organizationRole === 'member'">Mitglied</span>
        </div>
    </button>


    <button mat-menu-item (click)="organization()" [matTooltip]="'Aktuelle Organisation'" *ngIf="organizationCount == 1">
        <mat-icon [svgIcon]="'heroicons_outline:office-building'"></mat-icon>
        <div>
            {{organizationName}}
            <span class="role-info" *ngIf="organizationRole === 'administrator'">Administrator</span>
            <span class="role-info" *ngIf="organizationRole === 'creator'">Ersteller</span>
            <span class="role-info" *ngIf="organizationRole === 'member'">Mitglied</span>
        </div>

    </button>


    <!--   <button mat-menu-item (click)="createOrganization()" [matTooltip]="'Organisation erstellen'" *ngIf="organizationCount == 0">
           <mat-icon class="text-sm text-primary-600" [svgIcon]="'heroicons_outline:office-building'"></mat-icon>
           <span class="text-sm text-primary-600">Organisation erstellen?</span>
       </button>-->

    <mat-divider class="my-2"></mat-divider>
    <button mat-menu-item (click)="settings()">
        <mat-icon [svgIcon]="'heroicons_outline:cog'"></mat-icon>
        <span>Einstellungen</span>
    </button>


    <!--  <button mat-menu-item (click)="openDrawer()">
           <mat-icon [svgIcon]="'heroicons_outline:paint'"></mat-icon>
           <span>Darstellung</span>
       </button>-->
    <button mat-menu-item
            [matMenuTriggerFor]="userStatus">
        <mat-icon [svgIcon]="'heroicons_outline:dots-circle-horizontal'"></mat-icon>
        <span>Status</span>
    </button>

    <mat-divider class="my-2"></mat-divider>
    <button mat-menu-item
            (click)="signOut()">
        <mat-icon [svgIcon]="'heroicons_outline:logout'"></mat-icon>
        <span>Abmelden</span>
    </button>
</mat-menu>

<mat-menu class="user-status-menu"
          #userStatus="matMenu">
    <button mat-menu-item
            (click)="updateUserStatus('online')">
        <span class="w-4 h-4 mr-3 rounded-full bg-green-500"></span>
        <span>Verfügbar</span>
    </button>
    <button mat-menu-item
            (click)="updateUserStatus('away')">
        <span class="w-4 h-4 mr-3 rounded-full bg-amber-500"></span>
        <span>Abwesend</span>
    </button>
    <button mat-menu-item
            (click)="updateUserStatus('busy')">
        <span class="w-4 h-4 mr-3 rounded-full bg-red-500"></span>
        <span>Beschäftigt</span>
    </button>
    <button mat-menu-item
            (click)="updateUserStatus('not-visible')">
        <span class="w-4 h-4 mr-3 rounded-full bg-gray-400"></span>
        <span>Als Offline anzeigen</span>
    </button>
</mat-menu>



<mat-menu class="user-status-menu"
          #organisationsMenu="matMenu">

    <h6 class="m-4 text-primary-500  font-medium">Organisation wechseln zu:</h6>

    <ng-container *ngFor="let organization of organizationList;">

        <button mat-menu-item *ngIf="organizationName != organization.organizationName"
                (click)="setActiveOrganization(organization.organizationId)">
            <span>{{organization.organizationName}}</span>





        </button>



    </ng-container>
</mat-menu>



